<template>
  <div>
    <v-container v-if="postID == 10">
      <p class="display-3 font-weight-light	text-center pa-4">Why Purchase a Sex Doll?</p>
      <p class="overline text-center">By <b>Brian Lancaster</b> | January 24,2020</p>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <v-img
            class="white--text align-end"
            height="400px"
            :src="require('../assets/img/blog/10.jpg')"
          ></v-img>
          <p class="display-1 mb-0 pt-5">They are no longer taboo!</p>
          <p class="pt-5 subtitle-1 font-weight-light">As you probably know, sex dolls have been around for long time, but still many customers feel ashamed when contacting us or requesting information about sex dolls as if sex dolls were a taboo topic, something bad or illegal. But the reality is that sex dolls are very popular these days and even more accepted in modern society. Especially now that sex doll manufacturers are developing super realistic dolls and in the future we will see life-like sex dolls with artificial intelligence that promise to give them even more of a human-like feel. Experts believe that relationships with sex dolls or love dolls will be more common in the next few years as robotics, virtual reality and computing will allow love dolls to think and act as humans.</p>
          <ul class="subtitle-1 font-weight-light">
            <li>Check out our super realistic and interactive Artificial Intelligence <router-link to="/product?doll=194" style="text-decoration:underline;">Sex Doll Robots!</router-link></li>
            <li>Or some of our hand painted hyper realistic flesh <router-link to="/product?doll=174" style="text-decoration:underline;">Starpery Dolls</router-link></li>
          </ul>
          <p class="subtitle-1 font-weight-light pt-5">If we look at international news or just Google about the topic you can find many articles about how men are developing special relationships with realistic sex dolls, and the reality is that sex dolls can bring many benefits to single men or women and even couples. Many women buy sex dolls for photography purposes, decoration or modeling, some customers have partners but they also use sex dolls as a way to experiment fantasies without hurting their other halves, and we have seen couples using realistic silicone sex dolls or love dolls to spice up their marriages. Having a sex doll is totally normal and developing a special relationship with her too.</p>
          <v-img
            class="white--text align-end"
            height="400px"
            :src="require('../assets/img/blog/102.jpg')"
          ></v-img>
          <p class="display-1 mb-0 pt-5">Sex Doll Benefits</p>
          <p class="subtitle-1 font-weight-light pt-5">Similar to other sex toys, realistic love dolls have many benefits. From sexual release to companion. Some customers also use sex dolls for photography, fashion and modeling. Even couples use sex dolls to spicy up their relationships. You first doll experience will be unique, and that doll will be there forever for you. Love dolls are great to hang out with; they will be loyal and always listen to you. They can also help you living those sexual adventures you were dreaming about.</p>
     
          <!-- <p class="body-1	font-weight-light pt-10"><b>3 COMMENTS</b></p>
          <v-list
            three-line="true"
            avatar="true"
          >
            <v-list-item-group v-model="item" color="primary">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                inactive="true"
              >
                <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title><v-rating v-model="rating" class="" background-color="warning lighten-3"
                                                                                       color="warning" dense></v-rating>
                  <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list> -->
        </div>
      </div>
    </v-container>

    <v-container v-if="postID == 20">
      <p class="display-3 font-weight-light	text-center pa-4">AI and Robotics</p>
      <p class="overline text-center">By <b>David Waldon</b> | February 06,2018</p>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <vue-picture-swipe class="imageWrapper" :items="photos_20" style="text-align:center;"></vue-picture-swipe>
          <p class="display-1 mb-0 pt-5">What is an artificial intelligence sex doll?</p>
          <p class="pt-5 subtitle-1 font-weight-light">Artificial intelligence is the emerging technology that simulates human intelligence into machines. These machines through programming act and think like normal human beings. AI is applied and commonly refers to any sought of programmed machine that can be able to exhibit characteristics associated with problem-solving and learning. This is the same way a human mind works.

In most scenarios, Artificial Intelligence is not provided to clients as a personal application. Contrary, the products you are using will be improved with AI capabilities. This is the same as Siri which was improved as a new application for new Apple products.</p>
          <ul class="subtitle-1 font-weight-light">
            <li>Check out our super realistic and interactive Artificial Intelligence <router-link to="/product?doll=194" style="text-decoration:underline;">Sex Doll Robots!</router-link></li>
            <li>Or some of our hand painted hyper realistic flesh <router-link to="/product?doll=174" style="text-decoration:underline;">Starpery Dolls</router-link></li>
          </ul>
          <p class="subtitle-1 font-weight-light pt-5">An artificial intelligence sex doll is a sex doll toy with improved AI capabilities. They are designed to provide extra pleasure to you. New uniquely designed sex dolls are driven by AI with self-awareness capabilities. They are not only wonderful and exceptional sex partners but are also a great companion 24/7. Most sex doll lovers like to have sex dolls with certain personality traits, and with the aid of AI tech, you can have the sex doll you have always dreamed of. Currently, there are places around the globe with fully designed AI sex dolls.</p>
          <v-img
            class="white--text align-end"
            height="400px"
            :src="require('../assets/img/home/slider3.jpg')"
          ></v-img>
          <p class="display-1 mb-0 pt-5">Sensors that Enhance the Experience</p>
          <p class="subtitle-1 font-weight-light pt-5">AI designed sex dolls have smart body sensors that react to your touch with ease. By touching her perfectly positioned body sensors, she will react with a smooth tone and also moan in relation to the excitement levels she is set up at. These smart sex sensors are found around the arms, vagina and breast areas. They react the same way a normal woman would when aroused.AI technology has made it possible for sex dolls to be warmed up to 37 degrees Celsius to fit the normal temperature of a woman.

Safety challenges evident with electric heat systems, have allowed for the emergence of battery-powered sex dolls. These sex dolls only need to be slightly super-heated to reach the normal temperature of a human being. Our engineering team has conducted various tests to ensure our electrically powered sex dolls are 100% certified. Only a fully integrated heating system like this can guarantee your sex dolls reaches normal body temperatures.

There is no other system with ability to allow direct switching on the heating function. The system also turns off automatically when the desired body temperature is reached. This means you can turn the heating before embarking on a house chore and will be ready when you are finished with work.

          </p>
          <!-- <p class="body-1	font-weight-light pt-10"><b>3 COMMENTS</b></p>
          <v-list
            three-line="true"
            avatar="true"
          >
            <v-list-item-group v-model="item" color="primary">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                inactive="true"
              >
                <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title><v-rating v-model="rating" class="" background-color="warning lighten-3"
                                                                                       color="warning" dense></v-rating>
                  <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list> -->
        </div>
      </div>
    </v-container>

    <v-container v-if="postID == 30">
      <p class="display-3 font-weight-light	text-center pa-4">Sex Doll Care</p>
      <p class="overline text-center">By <b>Amy Lambaugn</b> | March 17,2021</p>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <v-img
            class="white--text align-end"
            height="400px"
            :src="require('../assets/img/blog/302.jpg')"
          ></v-img>
          <p class="display-1 mb-0 pt-5">How to Clean your Sex Doll</p>
          <p class="pt-5 subtitle-1 font-weight-light">If you want to treat your sex doll in the best possible way, i recommend using these tools:

– Mild liquid soap or antibacterial soap

– Vaginal irrigator

– Warm water

– Soft cloth (ideally microfiber)

– Dry towel or chamois cloth

– Baby oil

– Talcum powder or corn starch (natural and non-toxic)

Start cleaning the doll with soap and water, this process can be done either in the shower or with a bucket, use a soft cloth and clean every part of the doll.

This is your real first meeting, get to know her well in every sensual curve.
Carefully clean every cavity, vagina, mouth and anus. To do that use the Kit as a gift, it will be much more convenient. If you have chosen the removable vagina, this step will be even easier, just wash the vagina directly under the jet of water.

Immediately after washing it, dry it well with a soft towel, avoid accelerating drying by leaving the doll near a radiator or any other heating device. To make sure it is dry, you can use a fan.

If your sex doll is dry, you will avoid the proliferation of bacteria and the accumulation of dirt that could cause bad smell. In addition, constant washing helps keep the doll fresh and pleasant over time.

Technically the doll can be cleaned every month. In any case, I advise you to clean the doll after each sexual use, in the affected areas. Also, if not used, I suggest you keep it away from dust and sunlight.</p>
          <ul class="subtitle-1 font-weight-light">
            <li>Check out our super realistic and interactive Artificial Intelligence <router-link to="/product?doll=194" style="text-decoration:underline;">Sex Doll Robots!</router-link></li>
            <li>Or some of our hand painted hyper realistic flesh <router-link to="/product?doll=174" style="text-decoration:underline;">Starpery Dolls</router-link></li>
          </ul>
          <p class="display-1 mb-0 pt-5">TPE Sex Doll Skin Care and Lubricants</p>
          <p class="subtitle-1 font-weight-light pt-5">Always use water-based lube while having sex with your life-size sex doll. It will help in better vaginal, anal and oral penetration without fear of damaging the doll.

If your love doll’s skin becomes tacky to touch, clean her with a good quality renewal powder to make her skin velvety smooth again.</p>
          <v-img
            class="white--text align-end"
            height="400px"
            :src="require('../assets/img/blog/30.jpg')"
          ></v-img>
          <p class="display-1 mb-0 pt-5">Doll TPE Skin Maintenance</p>
          <p class="subtitle-1 font-weight-light pt-5">In addition to cleaning your sex doll after each intimate encounter, you can preserve its beauty over time, with a simple erotic practice, a good massage.

In fact, for proper skin maintenance, at least once a month, after cleansing, do a nice full body massage. Especially on the breasts, butt and joints.

Always use a mild oil, the one for children is the most suitable.

The oil massage will protect the skin from wear, massage the joints well, they are the parts of the body that suffer the most stress.

As soon as you have completed the massage, absorb the excess oil with a cloth. Take some talc or corn starch (natural and non-toxic), spread the powder all over the body. Help yourself if you want with a brush.

This method helps to keep your doll’s skin always soft, fresh, elastic and resistant over time.</p>
          <p class="subtitle-1 font-weight-light">The skin of your sex doll is delicate, in addition to the advice just expressed, I suggest some precautions to avoid damaging the skin.

Avoid clothes with zippers or any object that can hurt the skin, if you use them be very careful. Make sure the clothes do not lose color, wash them before trying them on your sex doll. This way you will prevent unsightly spots on the skin.

Proper cleaning of the lifelike doll, keeps you safe from disease, you will be able to have unprotected sex with your doll if you follow the instructions for proper cleaning. If ejaculation occurs inside the vagina or in other cavities of the doll, you will have to do a very thorough cleaning every time.

If you use the vaginal irrigator, in the kit while racing, the operation will also be simpler and faster.

To further facilitate cleaning of the cavities, you can use a condom.</p>
          <!-- <p class="body-1	font-weight-light pt-10"><b>3 COMMENTS</b></p>
          <v-list
            three-line="true"
            avatar="true"
          >
            <v-list-item-group v-model="item" color="primary">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                inactive="true"
              >
                <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title><v-rating v-model="rating" class="" background-color="warning lighten-3"
                                                                                       color="warning" dense></v-rating>
                  <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list> -->
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import VuePictureSwipe from 'vue-picture-swipe';
    export default {
        data: () => ({
            rating:4.5,
            item: 5,
            postID: 0,
            photos_20:[
              {
                src: require('../assets/img/blog/20.jpg'),
                thumbnail: require('../assets/img/blog/20.jpg'),
                w: 600,
                h: 800
              },
              {
                src: require('../assets/img/blog/202.jpg'),
                thumbnail: require('../assets/img/blog/202.jpg'),
                w: 600,
                h: 800
              },
              {
                src: require('../assets/img/blog/203.jpg'),
                thumbnail: require('../assets/img/blog/203.jpg'),
                w: 600,
                h: 800},
              {
                src: require('../assets/img/blog/204.jpg'),
                thumbnail: require('../assets/img/blog/204.jpg'),
                w: 600,
                h: 800
              }],
            items: [
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
                    title: 'Lorem ipsum dolor?',
                    subtitle: "<span class='text--primary'>Ali Connors</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl tincidunt eget nullam non. Tincidunt arcu non sodales neque sodales ut etiam. Lectus arcu bibendum at varius vel pharetra. Morbi tristique senectus et netus et malesuada.\n" +
                        "\n",
                },
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
                    title: 'Lorem ipsum dolor <span class="grey--text text--lighten-1">4</span>',
                    subtitle: "<span class='text--primary'>to Alex, Scott, Jennifer</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore"
                },
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
                    title: 'Lorem ipsum dolor',
                    subtitle: "<span class='text--primary'>Sandra Adams</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                },
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
                    title: 'Lorem ipsum dolor',
                    subtitle: ""
                },
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
                    title: 'Lorem ipsum dolor',
                    subtitle: "<span class='text--primary'>Britta Holt</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                },
            ],
        }),
        mounted(){
          this.postID = this.$route.query.postid
        }
    }
</script>
