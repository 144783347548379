import { render, staticRenderFns } from "./Blog.vue?vue&type=template&id=63c10166&"
var script = {}
import style0 from "./Blog.vue?vue&type=style&index=0&id=63c10166&prod&lang=css&"


/* normalize component */
import normalizer from "!C:\\Users\\nicol\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports